/* Register.scss */
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    padding: 1rem;
  }
  
  .register-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    font-size: 0.9rem;
  
    @media (max-width: 480px) {
      padding: 1.5rem;
    }
  }
  
  h2 {
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
    text-align: left;
  
    label {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
  .radio-group {
    display: flex;
    align-items: center;
  
    .radio-label {
      margin-left: 0.5rem;
      margin-right: 1.5rem;
    }
  }
  
  .full-width {
    width: 100%;
  }
  
  .response-message {
    margin-top: 1rem;
    color: green;
    font-size: 0.9rem;
  }
  
  .register-footer {
    margin-top: 1rem;
  
    p {
      margin: 0.5rem 0;
  
      a {
        color: #007bff;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  