/* Login.scss */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    padding: 1rem;
}

.form-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    font-size: 0.9rem;

    @media (max-width: 480px) {
        padding: 1.5rem;
    }
}

h2 {
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;

    label {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.login-options {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.p-inputtext {
    width: 100%;
    border-radius: 5px;
    border-color: #f0f2f5;
  }