/* Layout.scss */
.layout {
    display: flex;
    height: 100vh;

    .layout-header {
        width: 100%;
        background-color: white;
        color: black;
    }

    .layout-sidebar {
        width: 250px;
        background-color: #f4f4f4;
        border-right: 1px solid #ddd;
    }

    .layout-content {
        flex: 1;
        padding: 1rem;
        background-color: #fff;
    }
}