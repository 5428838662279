// buttons.scss
@import "variables";

// Mixin for button styles
@mixin button-styles($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
  border: none;
  padding: 0.5rem 1rem;

  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: darken($bg-color, 10%);
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
.background-primary {
  background-color: $primary-color !important;
}
.background-secondary {
  background-color: $secondary-color !important;
}
// Simple Buttons
.btn-primary {
  @include button-styles($primary-color, $primary-text-color);
}

.btn-secondary {
  @include button-styles($secondary-color, $secondary-text-color);
}

// Inverse Buttons
.btn-inverse {
  @include button-styles($inverse-bg-color, $inverse-text-color);
}

// Outline Buttons
@mixin button-outline-styles($border-color, $text-color) {
  background-color: transparent;
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: 25px;

  &:hover {
    background-color: $border-color;
    // color: $text-color;
    color: white;
  }
}

.btn-outline-primary {
  @include button-outline-styles($primary-color, $primary-color);
}

.btn-outline-secondary {
  @include button-outline-styles($secondary-color, $secondary-color);
}

// Button Hover Effect (if different from default)
.btn-hover {
  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}
