@import "variables";
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}
.summary-cards .p-card {
  text-align: center;
}

.bg-prmary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}

.mt-0 {
  margin-top: 0rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-5 {
  margin-top: 3rem;
}

.mr-0 {
  margin-right: 0rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 1.5rem;
}
.mr-5 {
  margin-right: 3rem;
}

.mb-0 {
  margin-bottom: 0rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 3rem;
}

.ml-0 {
  margin-left: 0rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 1rem;
}
.ml-4 {
  margin-left: 1.5rem;
}
.ml-5 {
  margin-left: 3rem;
}

.pt-0 {
  padding-top: 0rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pt-5 {
  padding-top: 3rem;
}

.pr-0 {
  padding-right: 0rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 1.5rem;
}
.pr-5 {
  padding-right: 3rem;
}

.pb-0 {
  padding-bottom: 0rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 1.5rem;
}
.pb-5 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 1rem;
}
.pl-4 {
  padding-left: 1.5rem;
}
.pl-5 {
  padding-left: 3rem;
}
