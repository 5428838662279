/* Home.css */
.logo {
    margin-left: 10px;
}

.hero-banner {
    /* background: url('/autism-adhd-banner.jpg') no-repeat center center; */
    background-size: cover;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.hero-content {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: Adds overlay effect */
    padding: 20px;
    border-radius: 10px;
}

.hero-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.p-menubar .p-menubar-start {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the logo */
    align-items: center;
  }